import axios from "axios";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ButtonAff from "../Button";

export default function Product({ categoryId }) {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState("");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalPages, setTotalPages] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `https://store.annk.info/api/category/${categoryId}`
        );
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `https://store.annk.info/api/product/category/${categoryId}?page=${page}`
        );
        setTotalPages(response.data.totalPages);
        if (response.data.products.length === 0) {
          setHasMore(false); // No more products to load
        } else {
          setProducts((prevProducts) => [
            ...prevProducts,
            ...response.data.products,
          ]);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
    fetchCategories();
  }, [categoryId, page]);

  const fetchMoreData = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    } else {
      setHasMore(false); // No more pages to load
    }
  };

  // Handle category change
  useEffect(() => {
    setPage(1);
    setProducts([]);
    setHasMore(true);
  }, [categoryId]);

  return (
    <>
      {/* <div className="orders-product">
        <div className="orders-block" id="orders-item2">
          <div className="orders-block__title">
            <h3>{categories.categoryName}</h3>{" "}
          </div>
          {products.map((item, index) => (
            <>
              <div className="orders-block__list " key={index}>
                <div className="order-item">
                  <div className="order-item--inner ">
                    <div className="order-image">
                      <div className="img-box ratiobox lazyloaded">
                        <img
                          className="lazyautosizes lazyloaded"
                          src={item.img}
                          alt={item.product}
                          sizes="100px"
                        />
                      </div>
                    </div>
                    <div className="order-info">
                      <h3>{item.product}</h3>
                      <div className="odi-desc">{item.product}</div>
                      <div className="odi-actions d-flex flex-wrap align-items-md-center justify-content-between">
                        <p className="odi-price">
                          <span>{item.price}</span>
                          <del>{item.price}</del>
                        </p>
                        <div className="odi-quantity" data-id={1031084690}>
                          <ButtonAff url={item.url} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
          <div className="orders-book__center">
            <button
              className="orders-book__more btn btn-main"
              data-id={1002553196}
              data-url="/collections/chao-hu-tieu-mi?view=order&page=2"
            >
              Xem thêm
            </button>
          </div>
        </div>
      </div> */}
      <div className="orders-product">
        <div className="orders-block" id="orders-item2">
          <div className="orders-block__title">
            <h3>{categories.categoryName}</h3>
          </div>
          <InfiniteScroll
            dataLength={products.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            endMessage={<p>Hết rồi nhen!!!</p>}
          >
            {products.map((item, index) => (
              <div className="orders-block__list" key={index}>
                <div className="order-item">
                  <div className="order-item--inner">
                    <div className="order-image">
                      <div className="img-box ratiobox lazyloaded">
                        <img
                          className="lazyautosizes lazyloaded"
                          src={item.img}
                          alt={item.product}
                          sizes="100px"
                        />
                      </div>
                    </div>
                    <div className="order-info">
                      <h3>{item.product}</h3>
                      <div className="odi-desc">{item.product}</div>
                      <div className="odi-actions d-flex flex-wrap align-items-md-center justify-content-between">
                        <p className="odi-price">
                          <span>{item.price}</span>
                          <del>{item.price}</del>
                        </p>
                        <div className="odi-quantity" data-id={1031084690}>
                          <ButtonAff url={item.url} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
}
