import React, { useState } from "react";
import Category from "../../components/Category";
import Product from "../../components/Product";

export default function Home() {
  const [selectedCategory, setSelectedCategory] = useState("66af980c9b95e6e1034545ed");

  return (
    <>
      <section className="section-index-listmenu" id="section-order-content">
        <div className="container-fluid">
          <div className="listmenu-heading">
            <h2>AFF LANDING</h2>
          </div>
          <div className="listmenu-content ">
            <div className="row">
              <div className="col-12 col-sm-12 col-lg-3 col-xl-3 listmenu-site-nav">
                <div className="orders-cate">
                  <Category onSelectCategory={setSelectedCategory} />
                </div>
              </div>
              <div className="col-12 col-sm-12  col-lg-6 col-xl-6  listmenu-site-prod">
                <div className="orders-product">
                  <Product categoryId={selectedCategory} />
                </div>
              </div>
              <div className="col-12 col-sm-12  col-lg-3 col-xl-3  listmenu-site-cart">
                <div className="sticky-right addthis_mobile">
                  <div className="cart-mobile d-lg-none">
                    <div className="flex-box d-flex">
                      <span className="cartmb--1">
                        <b>0</b> Món
                      </span>
                      <span className="cartmb--2">0₫</span>
                      <span className="cartmb--3">Xem chi tiết</span>
                    </div>
                  </div>
                  <div className="orders-cart d-flex flex-column">
                    <div className="orders-cart__content orders-cart-ajax ajax-here">
                      <div className="orders-cart--scroll">
                        <div className="orders-cart-item">
                          <p className="cart-empty text-center">
                            Giỏ hàng của bạn đang trống
                          </p>
                        </div>
                      </div>
                      <div className="orders-cart-bottom">
                        <p className="flex-total amount">
                          Tổng cộng: <span className="orders-total">0₫</span>
                        </p>
                      </div>
                    </div>
                    <div className="orders-cart__summary">
                      <div className="orders-cart-payment btn-check">
                        <a className="btn-link disabled" href="/checkout">
                          Thanh toán
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
