import React from "react";
import "./index.css";

export default function ButtonAff({ url }) {
  const handleClick = () => {
    if (url) {
      window.location.href = url;
    }
  };

  return (
    <>
      <button onClick={handleClick} className="button-aff">
        <span className="button-inner">
          <b>Mua đi chờ chi !!</b>
        </span>
      </button>
    </>
  );
}
