import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Category({ onSelectCategory }) {
  const [categories, setCategories] = useState([]);
  const [activeItem, setActiveItem] = useState("");

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "https://store.annk.info/api/category"
        );
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  console.log(categories);

  const handleClick = (e, categoryId) => {
    e.preventDefault();
    setActiveItem(categoryId);
    onSelectCategory(categoryId);
  };

  console.log(activeItem)

  return (
    <>
      <ul>
        {categories.map((category) => (
          <li
            key={category._id}
            className={activeItem === category._id ? "active" : ""}
          >
            <a
              href={`#${category.id}`}
              onClick={(e) => handleClick(e, category._id)}
            >
              {category.categoryName}
            </a>
          </li>
        ))}
      </ul>
    </>
  );
}
